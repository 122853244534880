import { postBodyRequest } from '@/utils/axios'

// 获取表格数据
export function page (params) {
  return postBodyRequest('/cis/position/page', params)
}

// 增加职务
export function add (params) {
  return postBodyRequest('/cis/position/add', params)
}
// 修改职务
export function edit (params) {
  return postBodyRequest('/cis/position/edit', params)
}
