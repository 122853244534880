<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <!-- 页面表格 -->
  <suy-table :reload="loadData" v-model:columns="columns">
    <!-- 页面头部查询模块 -->
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="职务名称" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchData">
            <template #icon>
              <SearchOutlined />
            </template>查询
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    <template #toolbarRight>
      <a-button type="primary" @click="add">
        <template #icon>
          <!-- 新增按钮 '+' 图标 -->
          <PlusCircleOutlined />
        </template>
        新增
      </a-button>
    </template>
    <!-- 表格主内容 -->
    <!-- dataSoure主数据数组、pagination分页器、loading页面是否开启加载中 -->
    <a-table :columns="[sortColumn, ...columns]" :row-key="record => record.id" :data-source="listData"
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <!-- 表格内容--序号 -->
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="edit(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm v-if="listData.length" title="确定删除?" @confirm="deleteItem(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </template>
    </a-table>
  </suy-table>
  <!-- 表格信息修改/增加 公共模块 -->
  <!-- maskClosable点击蒙层是否关闭-默认true,confirm-loading确认按钮是否开启加载中 -->
  <a-modal v-model:visible="modalVisible" :title="modalTitle" :maskClosable="false" :confirm-loading="false" @ok="save">
    <a-form ref="formRef" :model="form"  layout="vertical">
      <!-- required是否显示必填标志 -->
      <a-form-item label="名称" required>
        <a-input v-model:value="form.name" size="large" />
      </a-form-item>
      <a-form-item label="类型">
        <a-select v-model:value="form.type">
          <a-select-option v-for="item in positionType" :key="item.value">{{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-model:value="form.remark" showCount :maxlength="200" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { page, add as addItem, edit as editItem } from '@/api/system/position'
import { toRefs, reactive, onMounted } from 'vue'
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import SuyTable from '@/components/SuyTable'
export default {
  setup () {
    // 表格数据----state
    const state = reactive({
      loading: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {},
      listData: [],
      // 表格静态框架数据
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '类型',
          dataIndex: 'type.label'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          slots: {
            customRender: 'operation'
          }
        }
      ],
      positionType: [
        {
          label: '管理人员',
          value: 1
        },
        {
          label: '普通员工',
          value: 2
        }
      ],
      modalVisible: false, // '新增、修改'按钮事件模态框的显示隐藏默认配置项
      modalTitle: '', // '新增、修改'模态框的顶部标题
      confirmLoading: false, // 确认按钮是否开启加载中的默认配置项
      form: {},
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }]
      }
    })
    // 表格动态数据内容函数
    const loadData = async () => {
      state.loading = true
      page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.current =res. data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(()=>{state.loading = false})
      // const { data, data: { records } } = await page({
      //   ...state.searchForm,
      //   current: state.pagination.current,
      //   size: state.pagination.pageSize
      // })

      // state.listData = records
      // state.pagination.current = data.current
      // state.pagination.pageSize = data.size
      // state.pagination.total = data.total
      // state.loading = false
    }
    onMounted(loadData)// 视图被挂载后调用loadData异步
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 查询按钮功能---在第state.pagination.current页内查询指定内容
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    // '新增'按钮功能
    const add = () => {
      state.form = {
        name: '',
        type: '',
        remark: ''
      }
      state.modalVisible = true
      state.modalTitle = '新增职务'
    }
    // '修改'表格内容功能
    const edit = item => {
      state.form = { ...item, type: item.type.value }
      state.modalVisible = true
      state.modalTitle = '修改职务'
    }
    // '修改、增加数据请求-更新'
    const save = () => {
      // state.confirmLoading = true
      // 修改功能
      if (state.form.id) {
        editItem({ position: { ...state.form } }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.confirmLoading = false
            state.modalVisible = false
          }
          loadData()
        }).catch(err => {
          console.log(err)
        })
      } else {
        // 增加功能
        addItem({ position: { ...state.form } }).then(res => {
          console.log(res)
          if (res.code === 10000) {
            message.success(res.msg)
            state.confirmLoading = false
            state.modalVisible = false
          }
          loadData()
        }).catch(err => {
          console.log(err)
        })
      }
    }
    // 表格制动内容删除功能----待做
    const deleteItem = (id) => {

    }

    return {
      ...toRefs(state),
      loadData,
      handleTableChange,
      searchData,
      add,
      edit,
      save,
      deleteItem
    }
  },
  components: {
    PlusCircleOutlined,
    SearchOutlined,
    SuyTable
  }
}
</script>
